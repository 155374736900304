.App {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  max-width: 100%;
  background: linear-gradient(to bottom right, #3c2423, #284646);
}

h1 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: whitesmoke;
}

p {
  font-family: "Poppins", sans-serif;
font-size: 30px;
  font-size: small;
  color: whitesmoke;
}

Card {
  background-color: #284646;
}
