.testimonials-container{
    display: grid;
    gap: .5rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 40px repeat(3, auto);
    grid-column: 2 / span 10;
    width: 100%;
}

.testimonials-title {
    text-align: center;
    grid-row: 1;
    grid-column: span 3;
}

.testimonials-card1 {
    grid-row: 2;
    grid-column: 1 / span 2;
    max-width: 100%;
}

.testimonials-card2 {
    grid-row: 3;
    grid-column: 2 / span 2;
    max-width: 100%;
}
.testimonials-card3 {
    grid-row: 4;
    grid-column: 1 / span 2;
    max-width: 100%;
}