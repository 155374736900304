.services-comp-container{
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr auto auto;
    grid-column: 2 / span 10;
    width: 100%;
    padding-top: 50px;

}

.services-comp-title {
    grid-column: span 3;
    text-align: center;
    grid-row: 1;
    align-self: end; 
}

.card1 {
    grid-column: 1;
    grid-row: 2;
    margin-top: 0;
    padding-bottom: 20px;
}

.card2 {
    grid-column: 2;
    grid-row: 2;
    margin-top: 0;
    padding-bottom: 20px;
}

.card3 {
    grid-column: 3;
    grid-row: 2;
    padding-bottom: 20px;
}


@media (max-width: 1252px) {
    .services-comp-container {
        padding-top: 0px;
        grid-template-columns: repeat(6, auto);
        grid-template-rows: 10% auto auto;
    }
    .services-comp-title{
        grid-column: 2 / span 4;
        text-align: center;
    }
    .card1 {
        grid-column: 2 / span 2;
        grid-row: 2;
    }
    .card2 {
        grid-column: 4 / span 2;
        grid-row: 2;
    }
    .card3 {
        grid-column: 3 / span 2;
        grid-row: 3;
    }
  }

  @media (max-width: 870px) {
    .services-comp-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .services-comp-title {
        width: 100%;
        padding-top: 50px;
    }
  }