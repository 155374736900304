@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(-30%, -60%);
  }
  100% {
    opacity: 1;
    transform: translate(-30%, -50%);
  }
}

.hero-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;

  overflow: hidden;
}

.hero-nav {
  grid-row: 2;
  grid-column: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  transform: translate(0%, -10%);
}

.hero-nav a {
  color: #fbfbfb;
  text-decoration: none;
  font-size: 40px;
  position: relative;
  padding: 5px 0;
  transition: color 0.3s ease;
  background: linear-gradient(45deg, #a8d5ba, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-nav a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background: #fbfbfb;
  transition: width 0.3s ease;
}

.hero-nav a:nth-child(1) {
  transform: translateX(0);
}

.hero-nav a:nth-child(2) {
  transform: translateX(20px);
}

.hero-nav a:nth-child(3) {
  transform: translateX(80px);
}

.hero-nav a:nth-child(4) {
  transform: translateX(85px);
}

.hero-nav a:hover {
  color: #b0cfa0;
}

.hero-nav a:hover::after {
  width: 100%;
}

.hero-background {
  grid-column: 1 /-1;
  grid-row: 1;
  position: relative;
  height: 50vh;
}

.hero-video-container {
  position: relative;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%);
}

.hero-video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: none;
  z-index: 1;
}

.hero-title {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-30%, -30%);
  font-size: 80px;
  text-align: center;
  color: #fbfbfb;
  white-space: nowrap;
  opacity: 0;
  animation: fadeInUp 1.2s ease-out forwards;
  background: linear-gradient(45deg, #a8d5ba, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-content {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 50px;
  text-align: center;
  transform: translate(10%, 0%);
}

.hero-text {
  font-size: 20px;
  max-width: 100%;
  margin-bottom: 30px;
}

.hero-testimonials {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 3rem;
  margin-top: 20px;
}

.star img {
  width: 100px;
  margin: 0 auto;
  display: block;
}

.star p {
  margin-top: 10px;
  font-size: 1rem;
  color: #fbfbfb;
  text-align: center;
}

.star:hover img {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}
.star:hover p {
  color: #a8d5ba;
}

@media (max-width: 1497px) {
  .hero-title {
    font-size: 40px;
    transform: translate(-70%, -30%);
  }
}

@media (max-width: 820px) {
  .hero-background {
    height: 40vh;
  }

  .hero-video-container {
    width: 50%;
  }

  .hero-title {
    top: 50%;
    left: 35%;
    white-space: wrap;
  }

  .hero-nav {
    display: none;
  }

  .hero-mui-nav {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
  }

  .hero-content {
    grid-column: 1 /-1;
    transform: translate(0%, 0%);
    padding-bottom: 30px;
    justify-content: center;
  }

  .hero-text {
    font-size: 15px;
    max-width: 100vw;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .hero-text {
    font-size: 16px;
    padding: 0 1rem;
  }
}

@media (max-width: 520px) {
  .hero-video-container {
    width: 40%;
  }
  .hero-title {
    font-size: 30px;
    right: 2%;
  }
  .star img {
    width: 50px;
  }
  .star p {
    font-size: 0.9rem;
  }
}
