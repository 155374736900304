@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.Services {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  place-content: center;
}

.padding {
  padding-top: 50px;
}

.service-image {
  margin-top: 50px;
  width: 100%;
  height: 50vh;
  grid-column: 5 / span 4;
  border-radius: 30px;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.services-title {
  font-size: 30px;
  padding-top: 30px;
  padding-bottom: 20px;
  grid-column: span 12;
  text-align: center;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.services-text {
  font-size: 20px;
  grid-column: 2 / span 10;
  text-align: left;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.dropdown {
  margin-top: 50px;
  grid-column: 2 / span 2;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

@media (max-width: 923px) {
  .service-image {
    width: 100%;
    height: 50vh;
    grid-column: 4 / span 6;
    border-radius: 30px;
  }
}

@media (max-width: 650px) {
  .service-image {
    width: 100%;
    height: 30vh;
    grid-column: 4 / span 6;
    border-radius: 30px;
  }
}
