.about-container{
    padding-top: 50px;
    padding-bottom: 35px;
    display: grid;
    gap: .5rem;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 40px repeat(4, auto);
    grid-column: 2 / span 10;
    width: 100%;
    color:aqua
}

.about-title {
    grid-column: span 16;
    text-align: center;
}

.about-card {
    max-width: 100%;
    z-index: 2;
    grid-row: 2 / span 4;
    grid-column: 8 / span 4;
    margin-top: 175px;
    margin-bottom: 175px;
}

.man{
    z-index: 1;
    width: 100%; 
    height: auto;
    grid-column: 4 / span 5;
    grid-row: 2 / span 2;
    border-radius: 30px;
}

.group{
    z-index: 1;
    width: 100%; 
    height: auto;
    grid-column: 4 / span 5;
    grid-row: 4 / span 2;
    border-radius: 30px;
}


@media (max-width: 1381px) {
    .about-card {
        margin-top: 100px;
        margin-bottom: 100px;
        grid-column: 8 / span 6;
    }
  }

  @media (max-width: 1146px) {
    .about-card {
        margin-top: 100px;
        margin-bottom: 100px;
        grid-column: 8 / span 6;
    }
  }

  @media (max-width: 1034px) {
    .about-card {
        margin-top: 20px;
        margin-bottom: 20px;
        grid-column: 8 / span 7;
    }
  }

  @media (max-width: 919px) {
    .man{

        grid-column: 3 / span 6;
    }
    
    .group{

        grid-column: 3 / span 6;
    }
    .about-card {
        grid-column: 8 / span 5;
        padding-bottom: 15px;
    }
  }

  @media (max-width: 828px) {

    .about-card {

        grid-column: 8 / span 7;
    }
  }

  @media (max-width: 828px) {
    .about-card {
        margin-top: 25px;
        margin-bottom: 25px;
    }
  }

  @media (max-width: 669px) {
    .about-card {
        margin-top: 10px;
        margin-bottom: 10px;
    }
  }

  @media (max-width: 632px) {
    .man{

        grid-column: 1 / span 8;
    }
    
    .group{

        grid-column: 1 / span 8;
    }
    .about-card {
        margin-top: 50px;
        margin-bottom: 50px;
        grid-column: 8 / span 9;
    }
  }

  @media (max-width: 540px) {
    .about-card {
        margin-top: 5px;
        margin-bottom: 5px;
    }
  }

  @media (max-width: 632px) {
    .about-card {
        grid-column: 7 / span 10;
    }
  }

  @media (max-width: 919px) {
    .about-container{
        grid-template-rows: 10% repeat(8, auto);
    }    
    .man{
        grid-column: 1 / span 8;
        grid-row: 2 / span 4;
    }
    
    .group{
        grid-column: 9 / span 8;
        grid-row: 2 / span 4;
    }
    .about-card {
        margin-top: 30px;
        grid-column: 1 / span 16;
        grid-row: 5 / span 5;
        
    }
    .about-sub-text {
        padding-bottom: 10px;
    }
  }